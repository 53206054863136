import React from 'react';
import Layout from '../components/Layout';

const Service_one = () => {
  return (
    <Layout>
      <div id="main">
        <div className="services_section_banner">
          <div
            className="services_banner"
            style={{ backgroundImage: `url(../images/assists.jpg)` }}
          >
            <h1>Impuestos</h1>
          </div>
        </div>
        <div className="inner">
          <div className="services_section_1">
            <h1 className="services_section_1__title">Descripción</h1>
            <p className="services_section_1__paragraph">
              En la actualidad es una de las áreas más complejas, por lo cual se
              requiere de constante capacitación. En ANDIAN Consultores estamos
              capacitados para brindar asesoría de calidad y ser guías en la
              implementación de estratégias que ayuden a reducir la carga
              tributaria.
            </p>
          </div>
          <div className="card">
            <h1 style={{ color: '#000000', fontSize: 20 }}>
              Las obligaciones fiscales varían dependiendo de la actividad de
              cada contribuyente y las principales son:
            </h1>
            <ul style={{ color: '#000000' }}>
              <li>Calculo de ISR e IVA</li>
              <li>Declaraciones informativas</li>
              <li>Anual personas informativas</li>
              <li>Anual personas morales</li>
              <li>3% sobre nominas</li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Service_one;
